import { Card, CardContent, Chip } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import React from 'react'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'

const pageTitle = "Successful payment"

const SuccessfulPayment = () => (
  <Layout>
    <SEO title={pageTitle} description="The mission of WorkWithVisa.com is to find companies that provide Visa sponsorship for talented people."/>
    <Card>
        <CardContent>
        <Chip
            icon={<InfoIcon />}
            label={pageTitle}
            color="primary"
            variant="outlined"
        />
        <div>
        <br />
        Thank you for your payment!
        <br /><br />
        We are upgrading your profile!<br />
        Once we are done, you will receive an e-mail notification!
        </div>
        <br /><br />
        
        </CardContent>
    </Card>
  </Layout>
)

export default SuccessfulPayment
